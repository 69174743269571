function sleep(ms) { 
  return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * Export data to specified format
 * @param app - qlik app
 * @param id - qlik object id
 * @param format - OOXML, CSV_C, CSV_T see more docs
 * @param state - "A" (all values), "P" (possible values)
 */
 export function ExportData(app, id, format = "OOXML", state = "P"
 , onExportStart = null, onExportFinish=null) {
 app.visualization.get(id).then(function (vis) {
   vis.exportData({ format, state }).then(function (link) {
     // console.log(link);
     // window.location.replace(link);
     vis.close();

     if(onExportFinish)
       onExportFinish(link);
     else
       window.open(link, "_blank");
   }).catch(function (reason) {
     onExportFinish && onExportFinish(null, reason);
   });
   onExportStart && onExportStart();
 });
}

export function ApplyBookmark(app, id) {
  return app.bookmark.apply(id);
}

// To fix issue when bookmark created it can be published after some time
let justCreated = {};
const bmTimeoutMs = process.env.REACT_APP_AUTOSAVE_TIMEOUT_MS || 30000;
function setJustCreated(bid) {
  justCreated[bid] = setTimeout(() => clearJustCreated(bid), bmTimeoutMs);
}

function clearJustCreated(bid) {  
  delete justCreated[bid];
}

function isJustCreated(bid) {
  return justCreated[bid];
}

export async function CreateBookmark(app, title, description, sheetId) {
  //const b = await app.bookmark.create(title, description, sheetId);
  const b = await app.model.enigmaModel.createBookmarkEx(
    {
      qInfo: {
        qType: "bookmark"
      },
      qMetaDef: {
        title,
        description,
        isExtended: true,
        // approved: false,
        // published: false,
      },
      qIncludeVariables: true,
      // "qDistinctValues": false
    },
    []
  );

  const props = await b.getProperties();
  if(sheetId)
    props.page = sheetId; //`${window.location.pathname}${window.location.hash}`;

  if(props.qMetaDef && !props.qMetaDef.createdDate) {
    props.qMetaDef.createdDate = props.creationDate;
  }
  // props.qInfo.qType = `bookmark/${userId}`;
  await b.setProperties(props);
  setJustCreated(props.qInfo.qId);
  return b;
}

export function GetBookmark(app, id) {
  return app.model.enigmaModel.getBookmark(id);
}

export function RemoveBookmark(app, id) {
  return app.bookmark.remove(id);
}

function GetFullUserId(user) {
  return user ? `${user.UserDirectory}/${user.getFullUserId()}` : '';
}

export async function PublishBookmark(app, id, user) {
  // reload !
  // const books = await GetBookmarks(app, user);
  // console.log(books);
  if(isJustCreated(id))
    await sleep(bmTimeoutMs);

  let b = await app.model.enigmaModel.getBookmark(id);

  const userId = GetFullUserId(user);
  const props = await b.getProperties();
  props.qInfo.qType = `bookmark/${userId}`; // Makes published bookmark invisible to other users
  await b.setProperties(props);
  await b.publish();
}

export async function UnpublishBookmark(app, id) {
  const b = await app.model.enigmaModel.getBookmark(id);
  await b.unPublish();

  const props = await b.getProperties();
  props.qInfo.qType = 'bookmark';
  await b.setProperties(props);  
}

export async function GetBookmarks(app, user) {
    const userId = GetFullUserId(user);
    const bookmarks = await app.model.enigmaModel.getBookmarks({
     qOptions: {
       qTypes: [
         'bookmark', `bookmark/${userId}` // bookmark's types to load
       ],
       qData: {
        //  qBookmark: "", // do not load - just remove to load info
       },
      //  qData: {
      //    title: "/qMetaDef/title",
      //    creationDate: "/creationDate",
      //    description: "/qMetaDef/description",
      //    // sheetId: "/sheetId"
      //    ///selectionFields
      //  },
       qIncludePatches: false,
     }});

     // const l = await app.getList("BookmarkList");
     // return l.layout.qBookmarkList.qItems);
    return bookmarks;
}