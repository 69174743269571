import QlikCard from "components/QlikCard";
import QlikObject from "components/QlikObject";

export default function CompletedAnalysis({ title }) {
  return (
    <section className="content">
      <div className="content-header">
        <div className="row mb-2">
          <div className="col-md-10 col-sm-9 col-10">
            <h5 className="page-title"> {title} </h5>
          </div>
          <div className="col-md-2 col-sm-3 col-2">
            <QlikObject
              id="AcXPSU"
              height="35px"
              showLoader={false}
            />
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row justify-content-start">
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-2 pb-0">
                <QlikCard
                  id="dqJJrU" 
                  height="56px"
                  showHeader={false}
                  showMaximize={false}
                  showExport={false}
                />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 pb-0">
                <QlikCard
                  id="ASmEpSW"
                  height="56px"
                  showHeader={false}
                  showMaximize={false}
                  showExport={false}
                />
              </div>
        </div>

        <div className="row justify-content-start">
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
            {/* no of auctions */}
            <QlikCard
              id="aBSxcm" //ZEQSUAx
              height="200px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
            {/* no of participants */}
            <QlikCard
              id="MQJKx" //gpU
              height="200px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
            {/* income */}
            <QlikCard
              id="yNEVnP" //KkfPfHq
              height="200px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>

          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
            {/* land area */}
            <QlikCard
              id="KPkvvbQ" //uJmuj
              height="200px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
            {/* price increase */}
            <QlikCard
              id="QwLt" //XJEJrMJ
              height="200px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
            {/* avg bids */}
            <QlikCard
              id="LujvV" //jsCHmE
              height="200px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
            <div className="row">
              <div className="col-12">
                {/* bubble chart */}
                <QlikCard
                  id="cjNtFPN"
                  height="625px"
                  showLoader={true}
                  showExport={false}
                  showHeader={true}
                />
              </div>
            </div>
          </div>

          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
            <div className="row justify-content-left">
              </div>
              {/* bar chart */}
              <QlikCard
                id="sxSdYsn"
                height="275px"
                showLoader={true}
                showExport={false}
                showHeader={true}
              />
              {/* tree map */}
              <QlikCard
                id="RuSQBRa"
                height="300px"
                showLoader={true}
                showExport={false}
                showHeader={true}
              />
            </div>
          </div>

      </div>
    </section>
  );
}
