const Footer = () => (
  <footer className="main-footer p-1">
    <div className="float-right">
      <a href="https://www.qlik.com/" target="_blank" rel="noopener noreferrer">
        <img
          className="pr-4"
          src={`${process.env.PUBLIC_URL}logo---powered-by-Qlik.png`}
          alt="Qlik"
          height="24"
        />
      </a>
      <a
        href="https://www.rbcgrp.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="pr-4"
          src={`${process.env.PUBLIC_URL}RBCGroup_logo_resize.png`}
          alt="RBC Group"
          height="24"
        />
      </a>
      <a
        href="https://prozorro.sale/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="pr-4"
          src={`${process.env.PUBLIC_URL}logo_prozorro_sales_2020.png`}
          alt="Prozorro.Sale"
          height="24"
        />
      </a>
    </div>
  </footer>
);

export default Footer;
