import { Switch, Route, Redirect } from "react-router-dom";
//import usePageViews from "hooks/usePageViews";

// Pages
import Home from "pages/Home";
import Details from "pages/Details";
import CompletedAnalysis from "pages/CompletedAnalysis";
import Assets from "pages/Assets";
import Announcements from "pages/Announcements";

export default function Routes({ menuLayout }) {
  //usePageViews();

  return (
    <Switch>
      <Route exact path="/">
        <Home title={menuLayout.MenuItem1} menuItemsStrings={menuLayout}/>
      </Route>
      <Route path="/details">
        <Details title={menuLayout.MenuItem2} menuItemsStrings={menuLayout} />
      </Route>
      <Route path="/completedAnalysis">
        <CompletedAnalysis title={menuLayout.MenuItem3} menuItemsStrings={menuLayout} />
      </Route>
      <Route path="/assets">
        <Assets title={menuLayout.MenuItem4} menuItemsStrings={menuLayout} />
      </Route>
      <Route path="/announcements">
        <Announcements title={menuLayout.MenuItem6} menuItemsStrings={menuLayout} />
      </Route>
      <Redirect to="/" />
    </Switch>
  );
}
