import QlikCard from "components/QlikCard";
import QlikObject from "components/QlikObject";

export default function Assets({ title, menuItemsStrings }) {
  return (
    <section className="content">
      <div className="content-header">
        <div className="row mb-2">
          <div className="col-md-10 col-sm-9 col-10">
            <h5 className="page-title"> {title} </h5>
          </div>
          <div className="col-md-2 col-sm-3 col-2">
            <QlikObject id="AvMAPE" height="35px" showLoader={false} />
          </div>
        </div>
      </div>
      
      <div className="row justify-content-start">
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-2 pb-0">
            {/* periodText */}
            <QlikObject
              id="MsLhnEb"
              height="40px"
              showLoader={false}
            />
          </div>
          <div className="col-xs-13 col-sm-6 col-md-4 col-lg-4">
            {/* periodSelection */}
            <QlikObject
              id="hTkZda"
              height="40px"
              showLoader={false}
            />
          </div>
      </div>

      <div className="container-fluid">
        <div className="row justify-content-start">
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
            {/* totalNoOfAssets */}
            <QlikCard
              id="NgTRJS"
              height="210px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
            {/* noOfOpenAssets */}
            <QlikCard
              id="mSSYB"
              height="210px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
            {/* noOfAssetsSold */}
            <QlikCard
              id="rdnsmrP"
              height="210px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
            {/* valueOfAssetsSold */}
            <QlikCard
              id="mdBQSxB"
              height="210px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
            {/* incomeOfAssetsSold */}
            <QlikCard
              id="sNQApp"
              height="210px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
            {/* noOfCustodians */}
            <QlikCard
              id="TsYgUw"
              height="210px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          
        </div>

        <div className="row justify-content-start">
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
            {/* vizLastStatus */}
            <QlikCard
              id="LMVnP"
              height="40px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
            {/* vizHeadOrgan */}
            <QlikCard
              id="dmHGA"
              height="40px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            {/* vizPrivatizationScale */}
            <QlikCard
              id="pemJq"
              height="40px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-12">
            {/* vizTable */}
            <QlikCard
              id="YdpFC"
              height="600px"
              showLoader={true}
              showExport={true}
              showHeader={true}
              exportText = {menuItemsStrings.exportText}
              exportCompletedText = {menuItemsStrings.exportCompletedText}
              closeBtnText = {menuItemsStrings.closeBtnText}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
