import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//import ExpandableNavItem from "components/ExpandableNavItem";
import NavItem from "components/NavItem";

export default function MainMenu({ menuLayout, closeMenuHandler }) {
  let location = useLocation();
  useEffect(() => {
    closeMenuHandler && closeMenuHandler();
  }, [location, closeMenuHandler]);

  return (
    <>
      <NavItem
        to="/"
        text={menuLayout.MenuItem1}
        icon={<FontAwesomeIcon icon="home" />}
        //icon="nav-icon fas fa-home"
      />
      <NavItem
        to="/details"
        text={menuLayout.MenuItem2}
        //icon="nav-icon fas fa-industry"
        icon={<FontAwesomeIcon icon="money-check" />}
      />
      <NavItem
        to="/completedAnalysis"
        text={menuLayout.MenuItem3}
        icon={<FontAwesomeIcon icon="check-square" />}
        //icon="nav-icon fas fa-home"
      />
      <NavItem
        to="/assets"
        text={menuLayout.MenuItem4}
        // icon="nav-icon fas fa-university"
        icon={<FontAwesomeIcon icon="university" />}
      />
      <NavItem
        to="/announcements"
        text={menuLayout.MenuItem6}
        // icon="nav-icon fas fa-university"
        icon={<FontAwesomeIcon icon="calendar" />}
      />
      <li className="nav-item">
        <a
          className="nav-link jumpapp"
          href="https://bi.prozorro.sale/"
          target="_blank"
          rel="noreferrer"
        >
          <i className="lui-icon  lui-icon--log-in"></i>
          <p>{menuLayout.MenuItem5}</p>
        </a>
      </li>

      {/* <ExpandableNavItem
        text="Dashboard"
        icon="nav-icon lui-icon lui-icon--home"
      >
        <NavItem
          to="/"
          text="Dashboard"
          icon="nav-icon lui-icon lui-icon--shapes"
        />
        <NavItem
          to="/page2"
          text="Dashboard v2"
          icon="nav-icon lui-icon lui-icon--library"
        />
      </ExpandableNavItem> */}

      {/* <ExpandableNavItem
        text="Analysis"
        icon="nav-icon lui-icon lui-icon--view"
      >
        <NavItem
          to="/page3"
          text="Page 3"
          icon="lui-icon lui-icon--map nav-icon"
        />
        <NavItem
          to="/page4"
          text="Page 4"
          icon="lui-icon lui-icon--direct-discovery nav-icon"
        />        
      </ExpandableNavItem> */}
    </>
  );
}
