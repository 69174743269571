import QlikCard from "components/QlikCard";
import QlikObject from "components/QlikObject";

export default function HomePage({ title }) {
  return (
    <section className="content">
      <div className="content-header">
        <div className="row mb-2">
          <div className="col-md-10 col-sm-9 col-10">
            <h5 className="page-title"> {title} </h5>
          </div>
          <div className="col-md-2 col-sm-3 col-2">
            <QlikObject
              id="qjnkqX"
              height="35px"
              showLoader={false}
            />
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row justify-content-start">
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-2 pb-0">
                <QlikCard
                  id="MsLhnEb" 
                  height="56px"
                  showHeader={false}
                  showMaximize={false}
                  showExport={false}
                />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 pb-0">
                <QlikCard
                  id="hTkZda"
                  height="56px"
                  showHeader={false}
                  showMaximize={false}
                  showExport={false}
                />
              </div>
        </div>

        <div className="row justify-content-start">
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
            {/* no of auctions */}
            <QlikCard
              id="DkSbyW" //ZEQSUAx
              height="200px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
            {/* no of organizers */}
            <QlikCard
              id="LPcc" //gpU
              height="200px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
            {/* no of tenderers */}
            <QlikCard
              id="JpDb" //KkfPfHq
              height="200px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>

          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
            {/* land area */}
            <QlikCard
              id="mQPZwj" //uJmuj
              height="200px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
            {/* successful % */}
            <QlikCard
              id="jmACrU" //XJEJrMJ
              height="200px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
            {/* income */}
            <QlikCard
              id="pWmpfv" //jsCHmE
              height="200px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 ">
            <div className="row justify-content-left">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 pb-0">
                <QlikCard
                  id="khutAGG"
                  height="56px"
                  showHeader={false}
                  showMaximize={false}
                  showExport={false}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                {/* bar chart */}
                <QlikCard
                  id="JRX"
                  height="470px"
                  showLoader={true}
                  showExport={false}
                  showHeader={true}
                />
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <div className="row justify-content-left">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 pb-0">
                <QlikCard
                  id="pmAbvQ"
                  height="56px"
                  showHeader={false}
                  showMaximize={false}
                  showExport={false}
                />
              </div>
            </div>
            {/* map */}
            <QlikCard
              id="ZXLfy"
              height="470px"
              showLoader={true}
              showExport={false}
              showHeader={true}
            />
          </div>
        </div>

      </div>
    </section>
  );
}
