import { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import Loader from "components/Loader";

//import HomeFilters from "pages/Home/filters";

const YearMonthFilters = lazy(() =>
  import("pages/FiltersSidebar/YearMonthFilters")
);
const HomeFilters = lazy(() => import("pages/Home/filters"));
const DetailsFilters = lazy(() => import("pages/Details/filters"));
const CompletedAnalysisFilters = lazy(() => import("pages/CompletedAnalysis/filters"));
const AssetsFilters = lazy(() => import("pages/Assets/filters"));
const AnnouncementsFilters = lazy(() => import("pages/Announcements/filters"));

export default function SideBar() {
  return (
    <Suspense fallback={<Loader />}>
      <div className="p-2">
        <YearMonthFilters />
        <Switch>
          <Route exact path="/">
            <HomeFilters />
          </Route>
          <Route path="/details">
            <DetailsFilters />
          </Route>
          <Route path="/completedAnalysis">
            <CompletedAnalysisFilters />
          </Route>
          <Route path="/assets">
            <AssetsFilters />
          </Route>
          <Route path="/announcements">
            <AnnouncementsFilters />
          </Route>
        </Switch>
      </div>
    </Suspense>
  );
}
