import React from "react";
import ReactDOM from "react-dom";
import { HashRouter as Router } from "react-router-dom";
import "components/fontawesome";

// import "./admin-lte/adminlte.css";
import App from "./App";
import GlobalContext from "./GlobalContext";
// import { getTicket } from "./qlik-api/engine";
import { getUrlParameter, isTouchDevice } from "utils/utils";
import loadQlikTranslations from "utils/qlikTranslationLoad";
import "utils/classListPolyfill";

const main = async () => {
  // const isDev = process.env.NODE_ENV === "development";
  // console.log("IsDev", isDev);
  const isSecure = process.env.REACT_APP_QLIK_SERVER_SCHEME === "https"; //isDev ? true : window.location.protocol === "https:";
  const host = process.env.REACT_APP_QLIK_SERVER;
  /*
  isDev
    ? process.env.REACT_APP_QLIK_SERVER
    : window.location.hostname;
    */
  const port = process.env.REACT_APP_QLIK_SERVER_PORT || 443; //isDev ? 443 : window.location.port;
  const prefix = "/" + process.env.REACT_APP_QLIK_PROXY;
  /*
  isDev
    ? "/" + process.env.REACT_APP_QLIK_PROXY
    : window.location.pathname.substr(
        0,
        window.location.pathname.toLowerCase().lastIndexOf("/extensions") + 1
      );
  */

  const config = {
    host,
    prefix,
    port,
    isSecure,
    baseUrl:
      (isSecure ? "https://" : "http://") +
      host +
      (port ? ":" + port : "") +
      prefix +
      "resources",
  };

  const qlikTicket = getUrlParameter("qlikTicket");

  // add require.js to the document
  const requireTag = document.createElement('script');
  requireTag.type = 'text/javascript';
  requireTag.src=`${config.baseUrl}/assets/external/requirejs/require.js` + (qlikTicket ? `?qlikTicket=${qlikTicket}` : '');
  requireTag.id = 'qliksense-require-tag';
  document.head.appendChild(requireTag);
  requireTag.loaded = new Promise((resolve) => {
      requireTag.onload = () => {
          resolve();
      };
  });

  await requireTag.loaded;

  // add qlik style to the document
  const styleTag = document.createElement('link');
  styleTag.type = 'text/css';
  styleTag.rel = 'stylesheet';
  styleTag.href = `${config.baseUrl}/autogenerated/qlik-styles.css`
  styleTag.id = 'qliksense-styles-tag';
  document.head.appendChild(styleTag);
  styleTag.loaded = new Promise((resolve) => {
      styleTag.onload = () => {
          resolve();
      };
  });

  await styleTag.loaded;

  window.require.config(config);

  // console.log(window.require);
  // console.log(config);

  const lang = getUrlParameter("lang");
  const qlikLang = lang === "ua" ? "ru" : lang;
  const langToSet = qlikLang || window.navigator.language;

    // const translations = await loadQlikTranslations(langToSet);

    window.require(['js/qlik'], function (qlik) {
      qlik.on("error", function (error) {
        console.error("Qlik error", error);
      });
  
      const onTouchDevice = isTouchDevice();
    
      //set the language
      qlik.setLanguage(langToSet);
  
      window.require(['url-util'], function(urlUtils){
        (async function init() {
  
          const translations = await loadQlikTranslations(langToSet, urlUtils.staticResourcesPath);
  
          const app = qlik.openApp(process.env.REACT_APP_QLIK_APP, config);
  
          //applyURLSelections(app);
  
  
          if (lang === "ua") {
            app.field("_Language").selectValues(["Українська"], false, false);
          } else if (lang === "ru") {
            app.field("_Language").selectValues(["Русский"], false, false);
          } else {
            app.field("_Language").selectValues(["English"], false, false);
          }
  
          // Window resize handler - for devices like Galaxy Fold, etc
          // and when device is rotated
          (function () {
            window.addEventListener("resize", resizeThrottler, false);
            var resizeTimeout;
            function resizeThrottler() {
              // ignore resize events as long as an actualResizeHandler execution is in the queue
              if (!resizeTimeout) {
                resizeTimeout = setTimeout(function () {
                  resizeTimeout = null;
                  actualResizeHandler();
                  // The actualResizeHandler will execute at a rate of 15fps
                }, 66);
              }
            }
  
            function actualResizeHandler() {
              // console.log("Window resize handler");
              qlik.resize();
            }
          })();
  
          // Router is moved here to be able to navigate history inside App component
          ReactDOM.render(
            <React.StrictMode>
              <GlobalContext.Provider value={{ app, qlik, onTouchDevice, translations }}>
                <Router>
                  <App />
                </Router>
              </GlobalContext.Provider>
            </React.StrictMode>,
            document.getElementById("root")
          );
        }());
      });
    });
};

main();
